import * as colors from "./colors.js";
import fontMap from "./typography";

const palette = {
  //Primary
  primaryBlue: colors.primaryBlue,

  //Neutral
  neutralWhite: colors.white001,
  neutralGrey: colors.grey001,
  neutralDarkerGrey: colors.grey002,
  neutralDarkestGrey: colors.grey003,
  //Base
  basePink: colors.basePink001,
  baseOrange: colors.baseOrange001,
  baseYellow: colors.baseYellow001,
  basePurple: colors.basePurple001,
  baseTeal: colors.baseTeal001,
  baseGreen: colors.baseGreen001,
  //Base/hover
  hoverBasePink: colors.hoverBasePink002,
  hoverBaseOrange: colors.hoverBaseOrange002,
  hoverBaseYellow: colors.hoverBaseYellow002,
  hoverBasePurple: colors.hoverBasePurple002,
  hoverBaseTeal: colors.hoverBaseTeal002,
  hoverBaseGreen: colors.hoverBaseGreen002,
  hoverPrimary: colors.hoverPrimary001,
  hoverOutlineDark: colors.hoverOutlineDark,
  hoverOutlineLight: colors.hoverOutlineLight,
  hoverTextLight: colors.hoverTextLight,
  hoverBrightBlue: colors.hoverBrightBlue,
  hoverBrightYellow: colors.hoverBrightYellow,
  hoverBrightOrange: colors.hoverBrightOrange,
  hoverBrightGreen: colors.hoverBrightGreen,

  //Button
  pressedDark: colors.pressedDark,
  pressedLight: colors.pressedLight,
  pressedOutlineDark: colors.pressedOutlineDark,
  pressedOutlineLight: colors.pressedOutlineLight,

  //Level
  level1Green: colors.level1Green,
  level2Purple: colors.level2Purple,
  level3Pink: colors.level3Pink,
  //Tag
  tagBlue: colors.tagBlue001,
  tagRed: colors.tagRed001,
  tagGreen: colors.tagGreen001,
  tagPurple: colors.tagPurple001,
  //Tag/hover
  hoverTagBlue: colors.hoverTagBlue002,
  hoverTagRed: colors.hoverTagRed002,
  hoverTagGreen: colors.hoverTagGreen002,
  hoverTagPurple: colors.hoverTagPurple002,
  //Bright
  brightBlue: colors.brightBlue,
  brightOrange: colors.brightOrange,
  brightYellow: colors.brightYellow,
  brightGreen: colors.brightGreen,
  //Extra
  red001: colors.red001,
  red002: colors.red002,
  blue001: colors.blue001,
  liveRed: colors.liveRed,

  // Card
  orangeStar: colors.orangeStar,

  // DatePicker Calendar WeekDay Label
  weekDayGrey: colors.weekDayGrey,

  secondaryYellow: colors.secondaryYellow,
};

const fonts = {
  display1: fontMap.display["1"],
  display2: fontMap.display["2"],
  heading1: fontMap.heading["1"],
  heading2: fontMap.heading["2"],
  heading3: fontMap.heading["3"],
  heading4: fontMap.heading["4"],
  heading5: fontMap.heading["5"],
  heading6: fontMap.heading["6"],
  paragraph1: fontMap.paragraph["1"],
  paragraph2: fontMap.paragraph["2"],
  paragraph3: fontMap.paragraph["3"],
  paragraph4: fontMap.paragraph["4"],
};

const components = {
  button: {
    borderWidth: "2px",
    paddingLeft: "24px",
    paddingRight: "24px",
    imagePadding: "12px",
    fontSize: "18px",
    lineHeight: "24px",
    heightLarge: "72px",
    heightNormal: "50px",
    borderRadiusLarge: "36px",
    borderRadiusNormal: "25px",
    borderStyle: "solid",
  },
  card: {
    borderRadius: 24,
    padding: 24,
    spacing: 8,
    progressSize: 40,
    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.12)",
    liveCircleSize: "16px",
    liveCircleRadius: "128px",
    barChartHeight: 16,
    barChartRadius: 128,
  },
  promo: {
    height: {
      xs: 100,
      sm: 200,
      md: 554,
      lg: 400,
    },
    imgWidth: {
      xs: "0",
      sm: "250px",
      md: "554px",
      lg: "500px",
    },
    title: {
      xs: 24,
      sm: 40,
      md: 48,
      lg: 48,
    },
    subtitle: {
      xs: 0,
      sm: 16,
      md: 16,
      lg: 26,
    },
  },
  section: {
    sliderGradientLeft: "linear-gradient(90deg, #f5f5f7 70%, rgba(245, 245, 247, 0) 100%)",
    sliderGradientRight: "linear-gradient(270deg, #f5f5f7 70%, rgba(245, 245, 247, 0) 100%)",
    white: {
      sliderGradientLeft: "linear-gradient(to right, #FFFFFF, rgba(245, 245, 247, 0))",
      sliderGradientRight: "linear-gradient(270deg, #FFFFFF 70%, rgba(245, 245, 247, 0) 100%)",
    },
  },
  tab: {
    height: "50px",
    borderRadius: "128px",
  },
};

export const theme = {
  // colors
  palette,
  // Fonts
  fonts,
  components,
};

export default theme;
