import { useCallback, useEffect, useMemo, useState } from "react";
import geApi, { getGeHeaders, getGeJwt } from "../_utils/geApi";

export const useGeApi = (endpoint, params, method = "get") => {
  // Memoize the dependencies to prevent unnecessary re-renders
  const stableParams = useMemo(() => params, [JSON.stringify(params)]);
  const stableEndpoint = useMemo(() => endpoint, [endpoint]);
  const stableMethod = useMemo(() => method, [method]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let isMounted = true; // Track if component is still mounted
    setLoading(true);
    setError(false);

    async function fetchData() {
      try {
        const response = await geApi(stableEndpoint)[stableMethod](stableParams);

        if (isMounted) {
          setData(response.data);
        }
      } catch (err) {
        if (isMounted) {
          const errorInstance =
            err instanceof Error
              ? err // If it's already an Error, use it
              : new Error(String(err)); // Otherwise, create a new Error with err as the message

          setError(errorInstance);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    // Fetch data with cleanup function to prevent memory leaks
    fetchData();

    // Cleanup function to prevent memory leaks
    return () => (isMounted = false);
  }, [stableParams]);

  return { data, loading, error };
};

export const useGeJwt = () => {
  const jwt = getGeJwt();
  const headers = getGeHeaders(jwt);

  return {
    jwt,
    headers,
  };
};

export const useGeEndpoint = (endpoint, params) => {
  const api = useGeApi(endpoint, params);
  const { results: data = [], total, total_pages, page, per_page } = api.data || {};
  return {
    ...api,
    data,
    total,
    total_pages,
    page,
    per_page,
  };
};

export const useGeStreaks = (params) => {
  const api = useGeApi("users/me", params);
  const streaks = api.data?.progress?.goals?.streak || [];
  const findStreakById = (id) => streaks?.find((streak) => streak.goal_id === id);
  // TODO: move hardcode ids out of codebase
  // get from brand config instead
  const { featured_streak_ids } = {
    featured_streak_ids: {
      day: 1,
      week: 2,
      month: 3,
    },
  };

  const featured = Object.entries(featured_streak_ids);

  return {
    ...api,
    streaks,
    findStreakById,
    featured,
  };
};

export const useGeEndpointInfinite = (endpoint, initialParams) => {
  const [params, setParams] = useState(initialParams);
  const [currentPage, setCurrentPage] = useState(1);
  const [accumulatedData, setAccumulatedData] = useState([]);

  const { data, loading, error, total, total_pages, page, per_page } = useGeEndpoint(endpoint, {
    ...params,
    page: currentPage,
  });

  useEffect(() => {
    if (data.length > 0) {
      setAccumulatedData((prevData) => [...prevData, ...data]);
    }
  }, [data]);

  const loadMore = useCallback(() => {
    console.log("loadMore", currentPage, total_pages);
    if (currentPage < total_pages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, total_pages]);

  const hasMore = currentPage < total_pages;

  return {
    data: accumulatedData,
    loading,
    error,
    total,
    total_pages,
    page,
    per_page,
    loadMore,
    hasMore,
  };
};
